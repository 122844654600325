
import { computed, defineComponent, ref } from 'vue';
import { LogoutOutlined } from '@ant-design/icons-vue';
import { MenuProps } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Loader from '@/components/shared/Loader.vue';

export default defineComponent({
  name: 'AccountLayout',

  components: {
    LogoutOutlined,
    Loader,
  },

  setup() {
    let loading = ref<boolean>(false);
    const store = useStore();
    const router = useRouter();

    const handleLogout = async () => {
      try {
        loading.value = true;

        await store.dispatch('logout');

        router.push('/');
      } finally {
        loading.value = false;
      }
    };

    const handleMenuClick: MenuProps['onClick'] = (event) => {
      if (event.key === '1') {
        handleLogout();
      }
    };

    return {
      profile: computed(() => store.state.auth.profile),
      handleMenuClick,
      loading,
    };
  },
});
