import { BaseService } from './BaseService';
import { ICheckAuthResponse, ILoginParams, ILoginResponse } from '../types';

export class AuthService extends BaseService {
  readonly apiURL = '/api/auth';

  login = async (params: ILoginParams) => {
    const data = await this.post<ILoginResponse>(`${this.apiURL}/login`, params);

    return data;
  };

  checkAuth = async () => {
    const data = await this.get<ICheckAuthResponse>(`${this.apiURL}/check-auth`);

    return data;
  };

  logout = async () => {
    const data = await this.get(`${this.apiURL}/logout`);

    return data;
  };
}
