
import { defineComponent } from 'vue';
import AccountLayout from './AccountLayout.vue';
import AdminSidebar from '@/components/sidebar/AdminSidebar.vue';

export default defineComponent({
  name: 'AdminLayout',

  components: {
    AccountLayout,
    AdminSidebar,
  },
});
