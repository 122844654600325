import { IDomain, IDomainFormValues, IDropdownValue } from '@/types';

export const domainDropdownAdapter = (data: IDomain[]): IDropdownValue[] => {
  return (data || []).map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export const domainToFormAdapter = (data: IDomain): IDomainFormValues => {
  return {
    id: data.id,
    name: data.name,
    url: data.url,
    users: data.users.map((item) => ({
      id: item.id,
    })),
  };
};
