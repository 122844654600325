import { createStore } from 'vuex';
import alert from './alert';
import auth from './auth';
import domain from './domain';
import multibutton from './multibutton';
import user from './user';

export default createStore({
  modules: {
    alert,
    auth,
    domain,
    multibutton,
    user,
  },
});
