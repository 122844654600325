
import { ETypeAlertStore, EUserRole } from '@/types';
import { message } from 'ant-design-vue';
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'MainLayout',

  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const loading = ref<boolean>(true);

    onMounted(async () => {
      loading.value = true;

      await router.isReady();
      const data = await store.dispatch('checkAuth');

      /** если пользователь не авторизован, но страница этого требует,
       * то делаем редирект на авторизацию
       */
      if (route.meta.auth && !data.isAuth) {
        router.push('/');
        loading.value = false;
        return;
      }

      /** если пользователь авторизован, но страница этого не требует (авторизация),
       * то делаем редирект в кабинет
       */
      if (!route.meta.auth && data.isAuth) {
        const url = data.profile?.role === EUserRole.admin ? '/admin/domains' : '/client/domains';
        router.push(url);
        loading.value = false;
        return;
      }

      /** если пользователь авторизован, но его роль не совпадает с ролью страницы,
       * то делаем logout и редирект на авторизацию
       */
      if (data.isAuth && data.profile?.role !== route.meta.role) {
        await store.dispatch('logout');
        router.push('/');
        loading.value = false;
        return;
      }

      loading.value = false;
    });

    watch(
      () => store.getters.alertData,
      async () => {
        if (!store.getters.alertData) {
          return;
        }

        const { isAuth, type, message: messageStr } = store.getters.alertData;

        if (type === ETypeAlertStore.success) {
          message.success(messageStr);
        }

        if (isAuth && type === ETypeAlertStore.error) {
          await store.dispatch('logout');
          router.push('/');
        }

        if (!isAuth && type === ETypeAlertStore.error) {
          message.error(messageStr);
        }

        store.dispatch('clearAlert');
      },
    );

    return {
      loading,
    };
  },
});
