
import { defineComponent, reactive } from 'vue';
import AccountLayout from '@/layouts/AccountLayout.vue';
import DomainForm from '@/components/domain/DomainForm.vue';
import { ArrowLeftOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { IDomainFormValues } from '@/types';

export default defineComponent({
  name: 'AdminUserCreate',

  components: {
    AccountLayout,
    ArrowLeftOutlined,
    DomainForm,
  },

  setup() {
    const router = useRouter();

    const initValues = reactive<IDomainFormValues>({
      id: null,
      name: '',
      url: '',
      users: [],
    });

    const moveBack = () => {
      router.push('/admin/domains');
    };

    return {
      moveBack,
      initValues,
    };
  },
});
