import { EAccessMatch, EAccessType, EMultibuttonAction, EMultibuttonPlacement } from '@/types';

export const getActionTitle = (action: EMultibuttonAction): string => {
  let title = '';

  switch (action) {
    case EMultibuttonAction.link:
      title = 'Ссылка';
      break;

    case EMultibuttonAction.phone:
      title = 'Звонок';
      break;

    case EMultibuttonAction.menu:
      title = 'Меню';
      break;
  }

  return title;
};

export const getStatusTitle = (isActive: boolean): string => {
  return isActive ? 'Активен' : 'Остановлен';
};

export const getAccessTypeMap = () => {
  return new Map<EAccessType, string>()
    .set(EAccessType.allow, 'разрешить')
    .set(EAccessType.deny, 'запретить');
};

export const getAccessMatchMap = () => {
  return new Map<EAccessMatch, string>()
    .set(EAccessMatch.contains, 'содержит')
    .set(EAccessMatch.matches, 'совпадает');
};

export const getPlacementMap = () => {
  return new Map<EMultibuttonPlacement, string>()
    .set(EMultibuttonPlacement.topLeft, 'сверху - слева')
    .set(EMultibuttonPlacement.topRight, 'сверху - справа')
    .set(EMultibuttonPlacement.bottomLeft, 'снизу - слева')
    .set(EMultibuttonPlacement.bottomRight, 'снизу - справа')
    .set(EMultibuttonPlacement.bottomCenter, 'снизу - по центру');
};

export const getActionMap = () => {
  return new Map<EMultibuttonAction, string>()
    .set(EMultibuttonAction.link, 'Ссылка')
    .set(EMultibuttonAction.phone, 'Звонок')
    .set(EMultibuttonAction.menu, 'Меню');
};

export const getMenuActionMap = () => {
  return new Map<EMultibuttonAction, string>()
    .set(EMultibuttonAction.link, 'Ссылка')
    .set(EMultibuttonAction.phone, 'Звонок');
};
