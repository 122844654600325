
import { createVNode, defineComponent, onMounted, ref } from 'vue';
import {
  PlusCircleOutlined,
  FormOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue';
import AccountLayout from '@/layouts/AccountLayout.vue';
import { useStore } from 'vuex';
import { IDomain } from '@/types';
import { getActionTitle, getStatusTitle } from '@/utils';
import { useRouter } from 'vue-router';
import { Modal } from 'ant-design-vue';

const domainColumns = [
  { title: 'Наименование', dataIndex: 'name', key: 'name' },
  { title: 'Адрес', dataIndex: 'url', key: 'url' },
  { title: 'Мультикнопки', key: 'multibutton' },
  { title: 'Действия', key: 'action' },
];

const multibuttonColumns = [
  { title: 'Наименование', dataIndex: 'name', key: 'name' },
  { title: 'Картинка', key: 'image' },
  { title: 'Действие', dataIndex: 'action', key: 'action' },
  { title: 'Статус', key: 'status' },
  { title: 'Действия', key: 'buttons' },
];

export default defineComponent({
  name: 'ClientDomains',

  components: {
    AccountLayout,
    PlusCircleOutlined,
    FormOutlined,
    DeleteOutlined,
  },

  setup() {
    const router = useRouter();
    const loading = ref<boolean>(true);
    const domains = ref<IDomain[]>([]);
    const store = useStore();

    onMounted(async () => {
      loading.value = true;

      domains.value = await store.dispatch('fetchDomainList');

      loading.value = false;
    });

    const createMultibutton = (domainId: number) => {
      router.push({ path: '/client/multibutton/create', query: { domainId } });
    };

    const editMultibutton = (uuid: string) => {
      router.push(`/client/multibutton/edit/${uuid}`);
    };

    const deleteMultibutton = (uuid: string) => {
      Modal.confirm({
        title: 'Вы уверены?',
        icon: createVNode(ExclamationCircleOutlined),
        okText: 'Да',
        okType: 'danger',
        cancelText: 'Нет',
        async onOk() {
          try {
            loading.value = true;

            await store.dispatch('deleteMultibutton', uuid);

            domains.value = domains.value.map((domain) => ({
              ...domain,
              multibuttons: domain.multibuttons.filter((multibutton) => multibutton.uuid !== uuid),
            }));
          } finally {
            loading.value = false;
          }
        },
      });
    };

    return {
      loading,
      domains,
      domainColumns,
      multibuttonColumns,
      getActionTitle,
      getStatusTitle,
      createMultibutton,
      editMultibutton,
      deleteMultibutton,
    };
  },
});
