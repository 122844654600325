
/* eslint-disable no-useless-escape */
import { computed, defineComponent, onMounted, ref } from 'vue';
import AccountLayout from '@/layouts/AccountLayout.vue';
import MultibuttonForm from '@/components/multibutton/MultibuttonForm.vue';
import Loader from '@/components/shared/Loader.vue';
import { ArrowLeftOutlined, CopyOutlined } from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router';
import { multibuttonToFormAdapter } from '@/adapters';
import { EUserRole, IMultibutton, IMultibuttonFormValues } from '@/types';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';

export default defineComponent({
  name: 'MultibuttonEdit',

  components: {
    AccountLayout,
    ArrowLeftOutlined,
    MultibuttonForm,
    CopyOutlined,
    Loader,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const uuid = route.params.uuid;
    const loading = ref<boolean>(true);
    const store = useStore();
    const collapseKey = ref(['1']);
    const isAdmin = route.meta.role === EUserRole.admin;
    const backUrl = isAdmin ? '/admin/domains' : '/client/domains';

    const initValues = ref<IMultibuttonFormValues>({
      uuid: null,
      domainId: null,
      name: '',
      images: [],
      accesses: [],
      placement: null,
      action: null,
      actionValue: null,
      diameter: 100,
      menu: [],
      isActive: false,
    });

    const code = computed(() => {
      return `<!-- Legocar.Widget -->
<script type="text/javascript">
  (function(){
      window._LWA_BUTTON_UUID = '${initValues.value.uuid}';
      window._LWA_APP_DOMAIN = '${process.env.VUE_APP_SITE_URL}';
      d=document;s=d.createElement("script");s.src=window._LWA_APP_DOMAIN+"/mbutton/t.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);
  })();
<\/script>
<!-- /Legocar.Widget -->`;
    });

    onMounted(async () => {
      try {
        loading.value = true;

        const data: IMultibutton = await store.dispatch('fetchMultibutton', uuid);

        initValues.value = multibuttonToFormAdapter(data);
      } finally {
        loading.value = false;
      }
    });

    const moveBack = () => {
      router.push(backUrl);
    };

    const copyCode = () => {
      const textarea = document.createElement('textarea');
      textarea.value = code.value;
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';

      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      textarea.remove();

      message.success('Код скопирован');
    };

    const selectCode = () => {
      const range = new Range();
      const codeElem = document.querySelector('.multibutton-code__text');

      if (!codeElem) {
        return;
      }

      range.selectNode(codeElem);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
    };

    return {
      moveBack,
      initValues,
      loading,
      collapseKey,
      code,
      copyCode,
      selectCode,
      isAdmin,
    };
  },
});
