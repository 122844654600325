
import { defineComponent, onMounted, ref } from 'vue';
import AccountLayout from '@/layouts/AccountLayout.vue';
import DomainForm from '@/components/domain/DomainForm.vue';
import Loader from '@/components/shared/Loader.vue';
import { ArrowLeftOutlined } from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router';
import { IDomain, IDomainFormValues } from '@/types';
import { useStore } from 'vuex';
import { domainToFormAdapter } from '@/adapters';

export default defineComponent({
  name: 'AdminDomainEdit',

  components: {
    AccountLayout,
    ArrowLeftOutlined,
    DomainForm,
    Loader,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const id = route.params.id;
    const loading = ref<boolean>(true);
    const store = useStore();

    const initValues = ref<IDomainFormValues>({
      id: null,
      name: '',
      url: '',
      users: [],
    });

    onMounted(async () => {
      try {
        loading.value = true;

        const data: IDomain = await store.dispatch('fetchDomain', id);

        initValues.value = domainToFormAdapter(data);
      } finally {
        loading.value = false;
      }
    });

    const moveBack = () => {
      router.push('/admin/domains');
    };

    return {
      moveBack,
      initValues,
      loading,
    };
  },
});
