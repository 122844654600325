import { IMultibuttonAccess, IMultibuttonMenuItem } from '.';

export enum EMultibuttonPlacement {
  topLeft = 'top_left',
  topRight = 'top_right',
  bottomLeft = 'bottom_left',
  bottomRight = 'bottom_right',
  bottomCenter = 'bottom_center',
}

export enum EMultibuttonAction {
  link = 'link',
  phone = 'phone',
  menu = 'menu',
}

export interface IMultibutton {
  id: number;
  uuid: string;
  name: string;
  domainId: number;
  userId: number;
  diameter: number;
  image: string;
  placement: EMultibuttonPlacement;
  action: EMultibuttonAction;
  actionValue: string | null;
  accesses: IMultibuttonAccess[];
  menu: IMultibuttonMenuItem[];
  isActive: boolean;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
}
