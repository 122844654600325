export enum EAccessType {
  allow = 'allow',
  deny = 'deny',
}

export enum EAccessMatch {
  contains = 'contains',
  matches = 'matches',
}

export interface IMultibuttonAccess {
  type: EAccessType | null;
  match: EAccessMatch | null;
  value: string;
}
