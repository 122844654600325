import { AuthTokenError, DataError } from '@/services/errors';
import { IAlertMutationParams } from '@/types';

export const errorAdapter = (error: any, message = 'Неизвестная ошибка'): IAlertMutationParams => {
  if (error instanceof DataError) {
    return {
      message: error.message,
      isAuth: false,
    };
  }

  return {
    message,
    isAuth: error instanceof AuthTokenError,
  };
};
