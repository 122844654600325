
import { defineComponent, ref } from 'vue';
import { UserOutlined, GlobalOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'AdminSidebar',

  components: {
    UserOutlined,
    GlobalOutlined,
  },

  setup() {
    const selectedKeys = ref<string[]>(['1']);
    const route = useRoute();

    switch (route.name) {
      case 'AdminDomains':
        selectedKeys.value = ['1'];
        break;

      case 'AdminUsers':
        selectedKeys.value = ['2'];
        break;
    }

    return {
      selectedKeys,
    };
  },
});
