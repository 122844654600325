
import { defineComponent, onMounted, ref } from 'vue';
import AccountLayout from '@/layouts/AccountLayout.vue';
import UserForm from '@/components/user/UserForm.vue';
import Loader from '@/components/shared/Loader.vue';
import { ArrowLeftOutlined } from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router';
import { EUserRole, IUser } from '@/types';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AdminUserEdit',

  components: {
    AccountLayout,
    ArrowLeftOutlined,
    UserForm,
    Loader,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const id = route.params.id;
    const loading = ref<boolean>(true);
    const store = useStore();

    const initValues = ref<IUser>({
      id: 0,
      name: '',
      company: '',
      email: '',
      password: '',
      role: EUserRole.client,
    });

    onMounted(async () => {
      try {
        loading.value = true;

        const data: IUser = await store.dispatch('fetchUser', id);

        initValues.value = data;
      } finally {
        loading.value = false;
      }
    });

    const moveBack = () => {
      router.push('/admin/users');
    };

    return {
      moveBack,
      initValues,
      loading,
    };
  },
});
