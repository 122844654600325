
import { createVNode, defineComponent, onMounted, ref } from 'vue';
import {
  PlusOutlined,
  FormOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue';
import AdminLayout from '@/layouts/AdminLayout.vue';
import { useStore } from 'vuex';
import { IUser } from '@/types';
import { useRouter } from 'vue-router';
import { Modal } from 'ant-design-vue';

const columns = [
  { title: 'Имя', dataIndex: 'name', key: 'name' },
  { title: 'Компания', dataIndex: 'company', key: 'company' },
  { title: 'Email', dataIndex: 'email', key: 'email' },
  { title: 'Пароль', dataIndex: 'password', key: 'password' },
  { title: 'Действия', key: 'action' },
];

export default defineComponent({
  name: 'AdminUsers',

  components: {
    AdminLayout,
    PlusOutlined,
    FormOutlined,
    DeleteOutlined,
  },

  setup() {
    const router = useRouter();
    const loading = ref<boolean>(true);
    const users = ref<IUser[]>([]);
    const store = useStore();

    onMounted(async () => {
      loading.value = true;

      users.value = await store.dispatch('fetchUserList');

      loading.value = false;
    });

    const addUser = () => {
      router.push('/admin/user/create');
    };

    const editUser = (id: number) => {
      router.push(`/admin/user/edit/${id}`);
    };

    const deleteUser = (id: number) => {
      Modal.confirm({
        title: 'Вы уверены?',
        icon: createVNode(ExclamationCircleOutlined),
        okText: 'Да',
        okType: 'danger',
        cancelText: 'Нет',
        async onOk() {
          try {
            loading.value = true;

            await store.dispatch('deleteUser', id);

            users.value = users.value.filter((user) => user.id !== id);
          } finally {
            loading.value = false;
          }
        },
      });
    };

    return {
      loading,
      columns,
      users,
      editUser,
      deleteUser,
      addUser,
    };
  },
});
