export enum ETypeAlertStore {
  error = 'error',
  success = 'success',
}

export interface IAlertMutationParams {
  message?: string;
  isAuth?: boolean;
}

export interface IAlertStore {
  type: ETypeAlertStore | null;
  message: string | null;
  isAuth: boolean | null;
}
