import { IMultibutton, IMultibuttonFormValues } from '@/types';
import { BaseService } from './BaseService';

export class MultibuttonService extends BaseService {
  readonly apiURL = '/api/multibutton';

  save = async (params: IMultibuttonFormValues) => {
    const data = await this.post<string>(`${this.apiURL}/save`, params);

    return data;
  };

  delete = async (uuid: string) => {
    const data = await this.post<string>(`${this.apiURL}/delete`, {
      uuid,
    });

    return data;
  };

  getByUuid = async (uuid: string) => {
    const data = await this.get<IMultibutton>(`${this.apiURL}/get-by-uuid/${uuid}`);

    return data;
  };
}
