import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "account-wrapper"
}
const _hoisted_2 = { class: "account-title" }
const _hoisted_3 = { class: "account-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_arrow_left_outlined = _resolveComponent("arrow-left-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_DomainForm = _resolveComponent("DomainForm")!
  const _component_AccountLayout = _resolveComponent("AccountLayout")!

  return (_openBlock(), _createBlock(_component_AccountLayout, null, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_button, {
                type: "primary",
                shape: "circle",
                onClick: _withModifiers(_ctx.moveBack, ["prevent"])
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_arrow_left_outlined)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createTextVNode(" " + _toDisplayString(_ctx.initValues.name), 1)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_DomainForm, { "init-values": _ctx.initValues }, null, 8, ["init-values"])
            ])
          ]))
    ]),
    _: 1
  }))
}