const JWT_KEY = 'jwt';

export const getJwt = (): string => {
  return localStorage.getItem(JWT_KEY) || '';
};

export const setJwt = (jwt: string): void => {
  localStorage.setItem(JWT_KEY, jwt);
};

export const deleteJwt = (): void => {
  localStorage.removeItem(JWT_KEY);
};
