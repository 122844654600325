import { getJwt } from '@/utils';
import axios from 'axios';
import { AuthTokenError, DataError, IDataError } from './errors';

const apiOrigin = process.env.VUE_APP_API_URL;

const baseAxios = axios.create();
baseAxios.defaults.baseURL = apiOrigin;

type BaseGETArgs = Parameters<typeof baseAxios.get>;
type BasePOSTArgs = Parameters<typeof baseAxios.post>;

interface IDataResponse<T> {
  data: T & IDataError;
  status: boolean;
}

const getApi = () => {
  const jwt = getJwt();

  const api = axios.create({
    baseURL: apiOrigin,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });

  return api;
};

/** Базовый класс для создания сервиса */
export class BaseService {
  get = async <T>(...args: BaseGETArgs) => {
    try {
      const { data } = await getApi().get<IDataResponse<T>>(...args);

      if (!data.status) {
        throw new DataError(data.data.message);
      }

      return data.data;
    } catch (e: any) {
      /** ошибка авторизации */
      if (e.response?.status === 401) {
        throw new AuthTokenError();
      }

      throw e;
    }
  };

  post = async <T>(...args: BasePOSTArgs) => {
    try {
      const { data } = await getApi().post<IDataResponse<T>>(...args);

      if (!data.status) {
        throw new DataError(data.data.message);
      }

      return data.data;
    } catch (e: any) {
      /** ошибка авторизации */
      if (e.response?.status === 401) {
        throw new AuthTokenError();
      }

      throw e;
    }
  };
}
