import { AuthService } from '@/services';
import {
  IAuthMutationParams,
  IAuthStore,
  ICheckAuthResult,
  ILoginParams,
  ILoginResult,
} from '@/types';
import { deleteJwt, errorAdapter, getJwt, setJwt } from '@/utils';
import { ActionContext } from 'vuex';

const authService = new AuthService();

export default {
  state: {
    isAuth: false,
    profile: null,
  } as IAuthStore,

  mutations: {
    setAuth(state: IAuthStore, params: IAuthMutationParams) {
      state.isAuth = params.isAuth;
      state.profile = params.profile;
    },

    clearAuth(state: IAuthStore) {
      state.isAuth = false;
      state.profile = null;
    },
  },

  actions: {
    async login(
      { commit }: ActionContext<unknown, unknown>,
      params: ILoginParams,
    ): Promise<ILoginResult> {
      try {
        /** получаем токен */
        const { token, profile } = await authService.login(params);

        /** сохраняем токен */
        setJwt(token);

        /** коммитим результаты */
        commit('setAuth', { token, profile });

        return {
          isAuth: true,
          profile,
        };
      } catch (e) {
        commit('setError', errorAdapter(e, 'Ошибка авторизации'));
        throw e;
      }
    },

    async checkAuth({ commit }: ActionContext<unknown, unknown>): Promise<ICheckAuthResult> {
      const jwt = getJwt();

      if (!jwt) {
        return {
          isAuth: false,
        };
      }

      try {
        const { token, profile } = await authService.checkAuth();

        commit('setAuth', { token, profile });

        return {
          isAuth: true,
          profile,
        };
      } catch (e) {
        commit('clearAuth');
        deleteJwt();

        return {
          isAuth: false,
        };
      }
    },

    async logout({ commit }: ActionContext<unknown, unknown>) {
      await authService.logout();
      commit('clearAuth');
      deleteJwt();
    },
  },
};
