import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

const FONTAWESOME_ICONS = {
  'address-book': 'address-book',
  'user-circle': 'user-circle',
  envelope: 'envelope',
  university: 'university',
  book: 'book',
  calculator: 'calculator',
  'phone-square': 'phone-square',
  tag: 'tag',
  'paper-plane': 'paper-plane',
  car: 'car',
  refresh: 'refresh',
  rub: 'rub',
  'car-crash': 'car-crash',
  'credit-card': 'credit-card',
  cog: 'cog',
  tools: 'tools',
};

export const fontawesomeInit = () => {
  library.add(fas);
};

export const fontawesomeDropdown = () => {
  return Object.entries(FONTAWESOME_ICONS).map(([key, value]) => ({
    value: key,
    label: value,
  }));
};
