import { ETypeAlertStore, IAlertMutationParams, IAlertStore } from '@/types';
import { ActionContext } from 'vuex';

export default {
  state: {
    type: null,
    message: null,
    isAuth: null,
  } as IAlertStore,

  getters: {
    alertData(state: IAlertStore) {
      return state.message
        ? {
            type: state.type,
            message: state.message,
            isAuth: state.isAuth,
          }
        : null;
    },
  },

  mutations: {
    setSuccess(state: IAlertStore, params: IAlertMutationParams) {
      state.type = ETypeAlertStore.success;
      state.message = params.message || null;
      state.isAuth = params.isAuth || null;
    },

    setError(state: IAlertStore, params: IAlertMutationParams) {
      state.type = ETypeAlertStore.error;
      state.message = params.message || null;
      state.isAuth = params.isAuth || null;
    },

    clearAlert(state: IAlertStore) {
      state.type = null;
      state.message = null;
      state.isAuth = null;
    },
  },

  actions: {
    clearAlert({ commit }: ActionContext<unknown, unknown>) {
      commit('clearAlert');
    },
  },
};
