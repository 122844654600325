
import { defineComponent, PropType, reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { IUser, IUserForm } from '@/types';
import { randomString } from '@/utils';

export default defineComponent({
  name: 'UserForm',

  props: {
    initValues: {
      type: Object as PropType<IUser>,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();
    const store = useStore();
    const loading = ref<boolean>(false);

    const formValues = reactive<IUserForm>({
      ...props.initValues,
    });

    const setPassword = () => {
      formValues.password = randomString();
    };

    const onFinish = async (values: IUserForm) => {
      try {
        loading.value = true;

        await store.dispatch('saveUser', {
          ...values,
          id: props.initValues.id,
          role: props.initValues.role,
        });

        message.success('Данные успешно сохранены');
        router.push(`/admin/users`);
      } finally {
        loading.value = false;
      }
    };

    return {
      onFinish,
      formValues,
      loading,
      setPassword,
    };
  },
});
