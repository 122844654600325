
import { EUserRole } from '@/types';
import { message } from 'ant-design-vue';
import { defineComponent, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AuthLayout from '../layouts/AuthLayout.vue';

interface IFormValues {
  email: string;
  password: string;
}

export default defineComponent({
  name: 'Auth',

  components: {
    AuthLayout,
  },

  setup() {
    const router = useRouter();
    const store = useStore();

    const formValues = reactive<IFormValues>({
      email: '',
      password: '',
    });

    let loading = ref<boolean>(false);

    const onFinish = async (values: IFormValues) => {
      try {
        loading.value = true;

        const data = await store.dispatch('login', values);

        if (data.profile.role === EUserRole.client) {
          router.push('/client/domains');
          return;
        }

        if (data.profile.role === EUserRole.admin) {
          router.push('/admin/domains');
          return;
        }

        message.error('Неизвестный пользователь');
      } finally {
        loading.value = false;
      }
    };

    return {
      formValues,
      onFinish,
      loading,
    };
  },
});
