import { userDropdownAdapter } from '@/adapters';
import { UserService } from '@/services';
import { IDropdownValue, IUser } from '@/types';
import { errorAdapter } from '@/utils';
import { ActionContext } from 'vuex';

const userService = new UserService();

export default {
  actions: {
    async fetchUserList({ commit }: ActionContext<unknown, unknown>): Promise<IUser[]> {
      try {
        const data = await userService.getList();

        return data;
      } catch (e) {
        commit('setError', errorAdapter(e, 'Ошибка при получении списка пользователей'));
        return [];
      }
    },

    async fetchDropdownUsers({
      commit,
    }: ActionContext<unknown, unknown>): Promise<IDropdownValue[]> {
      try {
        const data = await userService.getList();

        return userDropdownAdapter(data);
      } catch (e) {
        commit('setError', errorAdapter(e, 'Ошибка при получении списка пользователей'));
        return [];
      }
    },

    async saveUser({ commit }: ActionContext<unknown, unknown>, params: IUser): Promise<string> {
      try {
        const data = await userService.save(params);

        return data;
      } catch (e) {
        commit('setError', errorAdapter(e, 'Ошибка при сохранении пользователя'));
        throw e;
      }
    },

    async deleteUser({ commit }: ActionContext<unknown, unknown>, id: number): Promise<string> {
      try {
        const data = await userService.delete(id);

        return data;
      } catch (e) {
        commit('setError', errorAdapter(e, 'Ошибка при удалении пользователя'));
        throw e;
      }
    },

    async fetchUser({ commit }: ActionContext<unknown, unknown>, id: number): Promise<IUser> {
      try {
        const data = await userService.getById(id);

        return data;
      } catch (e) {
        commit('setError', errorAdapter(e, 'Ошибка при получении данных о пользователе'));
        throw e;
      }
    },
  },
};
