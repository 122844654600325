import { domainDropdownAdapter } from '@/adapters';
import { DomainService } from '@/services';
import { IDomain, IDomainFormValues, IDropdownValue } from '@/types';
import { errorAdapter } from '@/utils';
import { ActionContext } from 'vuex';

const domainService = new DomainService();

export default {
  actions: {
    async fetchDomainList({ commit }: ActionContext<unknown, unknown>): Promise<IDomain[]> {
      try {
        const data = await domainService.getList();

        return data;
      } catch (e) {
        commit('setError', errorAdapter(e, 'Ошибка при получении списка доменов'));
        return [];
      }
    },

    async fetchDropdownDomains({
      commit,
    }: ActionContext<unknown, unknown>): Promise<IDropdownValue[]> {
      try {
        const data = await domainService.getList();

        return domainDropdownAdapter(data);
      } catch (e) {
        commit('setError', errorAdapter(e, 'Ошибка при получении списка доменов'));
        return [];
      }
    },

    async saveDomain(
      { commit }: ActionContext<unknown, unknown>,
      params: IDomainFormValues,
    ): Promise<string> {
      try {
        const data = await domainService.save(params);

        return data;
      } catch (e) {
        commit('setError', errorAdapter(e, 'Ошибка при сохранении домена'));
        throw e;
      }
    },

    async deleteDomain({ commit }: ActionContext<unknown, unknown>, id: number): Promise<string> {
      try {
        const data = await domainService.delete(id);

        return data;
      } catch (e) {
        commit('setError', errorAdapter(e, 'Ошибка при удалении домена'));
        throw e;
      }
    },

    async fetchDomain({ commit }: ActionContext<unknown, unknown>, id: number): Promise<IDomain> {
      try {
        const data = await domainService.getById(id);

        return data;
      } catch (e) {
        commit('setError', errorAdapter(e, 'Ошибка при получении данных о домене'));
        throw e;
      }
    },
  },
};
