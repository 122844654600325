import { EUserRole } from '@/types';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Auth from '../views/Auth.vue';
import ClientDomains from '../views/client/ClientDomains.vue';
import MultibuttonCreate from '../views/shared/MultibuttonCreate.vue';
import MultibuttonEdit from '../views/shared/MultibuttonEdit.vue';
import AdminDomains from '../views/admin/AdminDomains.vue';
import AdminDomainCreate from '../views/admin/AdminDomainCreate.vue';
import AdminDomainEdit from '../views/admin/AdminDomainEdit.vue';
import AdminUsers from '../views/admin/AdminUsers.vue';
import AdminUserCreate from '../views/admin/AdminUserCreate.vue';
import AdminUserEdit from '../views/admin/AdminUserEdit.vue';

const clientRoutes: Array<RouteRecordRaw> = [
  {
    path: '/client/domains',
    name: 'ClientDomains',
    component: ClientDomains,
    meta: { auth: true, role: EUserRole.client },
  },
  {
    path: '/client/multibutton/create',
    name: 'ClientMultibuttonCreate',
    component: MultibuttonCreate,
    meta: { auth: true, role: EUserRole.client },
  },
  {
    path: '/client/multibutton/edit/:uuid',
    name: 'ClientMultibuttonEdit',
    component: MultibuttonEdit,
    meta: { auth: true, role: EUserRole.client },
  },
];

const adminRoutes: Array<RouteRecordRaw> = [
  {
    path: '/admin/domains',
    name: 'AdminDomains',
    component: AdminDomains,
    meta: { auth: true, role: EUserRole.admin },
  },
  {
    path: '/admin/users',
    name: 'AdminUsers',
    component: AdminUsers,
    meta: { auth: true, role: EUserRole.admin },
  },
  {
    path: '/admin/user/create',
    name: 'AdminUserCreate',
    component: AdminUserCreate,
    meta: { auth: true, role: EUserRole.admin },
  },
  {
    path: '/admin/user/edit/:id',
    name: 'AdminUserEdit',
    component: AdminUserEdit,
    meta: { auth: true, role: EUserRole.admin },
  },
  {
    path: '/admin/domain/create',
    name: 'AdminDomainCreate',
    component: AdminDomainCreate,
    meta: { auth: true, role: EUserRole.admin },
  },
  {
    path: '/admin/domain/edit/:id',
    name: 'AdminDomainEdit',
    component: AdminDomainEdit,
    meta: { auth: true, role: EUserRole.admin },
  },
  {
    path: '/admin/multibutton/create',
    name: 'AdminMultibuttonCreate',
    component: MultibuttonCreate,
    meta: { auth: true, role: EUserRole.admin },
  },
  {
    path: '/admin/multibutton/edit/:uuid',
    name: 'AdminMultibuttonEdit',
    component: MultibuttonEdit,
    meta: { auth: true, role: EUserRole.admin },
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Auth',
    component: Auth,
    meta: { auth: false, role: null },
  },
  ...clientRoutes,
  ...adminRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
