
import { defineComponent, reactive } from 'vue';
import AccountLayout from '@/layouts/AccountLayout.vue';
import MultibuttonForm from '@/components/multibutton/MultibuttonForm.vue';
import { ArrowLeftOutlined } from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router';
import {
  EMultibuttonAction,
  EMultibuttonPlacement,
  EUserRole,
  IMultibuttonFormValues,
} from '@/types';

export default defineComponent({
  name: 'MultibuttonCreate',

  components: {
    AccountLayout,
    ArrowLeftOutlined,
    MultibuttonForm,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const defaultDomainId = route.query.domainId ? Number(route.query.domainId) : null;
    const isAdmin = route.meta.role === EUserRole.admin;
    const backUrl = isAdmin ? '/admin/domains' : '/client/domains';

    const initValues = reactive<IMultibuttonFormValues>({
      uuid: null,
      domainId: defaultDomainId,
      name: '',
      images: [],
      accesses: [],
      placement: EMultibuttonPlacement.bottomRight,
      action: EMultibuttonAction.link,
      actionValue: null,
      diameter: 100,
      menu: [],
      isActive: true,
    });

    const moveBack = () => {
      router.push(backUrl);
    };

    return {
      moveBack,
      initValues,
      isAdmin,
    };
  },
});
