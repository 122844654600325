export enum EUserRole {
  admin = 'admin',
  client = 'client',
}

export interface IProfile {
  id: number;
  name: string;
  company: string;
  role: EUserRole;
}
