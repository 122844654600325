
import { defineComponent, onMounted, PropType, reactive, ref } from 'vue';
import { IDomainFormUser, IDomainFormValues, IDropdownValue } from '@/types';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'DomainForm',

  components: {
    PlusOutlined,
    MinusCircleOutlined,
  },

  props: {
    initValues: {
      type: Object as PropType<IDomainFormValues>,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();
    const loading = ref<boolean>(false);
    const dropdownUsers = ref<IDropdownValue[]>([]);
    const router = useRouter();

    const formValues = reactive<IDomainFormValues>({
      ...props.initValues,
    });

    onMounted(async () => {
      dropdownUsers.value = await store.dispatch('fetchDropdownUsers');
    });

    const onFinish = async (values: IDomainFormValues) => {
      try {
        loading.value = true;

        await store.dispatch('saveDomain', {
          ...values,
          id: props.initValues.id,
        });

        message.success('Данные успешно сохранены');
        router.push(`/admin/domains`);
      } finally {
        loading.value = false;
      }
    };

    /** добавление/удаление пользователей */

    const removeUser = (item: IDomainFormUser) => {
      const index = formValues.users.indexOf(item);

      if (index !== -1) {
        formValues.users.splice(index, 1);
      }
    };

    const addUser = () => {
      formValues.users.push({
        id: null,
      });
    };

    return {
      onFinish,
      formValues,
      loading,
      dropdownUsers,
      removeUser,
      addUser,
    };
  },
});
