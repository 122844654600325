import { IMultibutton, IMultibuttonFormValues } from '@/types';

export const multibuttonToFormAdapter = (data: IMultibutton): IMultibuttonFormValues => {
  return {
    uuid: data.uuid,
    domainId: data.domainId,
    name: data.name,
    images: [
      {
        uid: data.uuid,
        name: 'image.png',
        status: 'done',
        url: data.image,

        response: {
          uid: data.uuid,
          name: 'image.png',
          status: 'done',
          url: data.image,
        },
      },
    ],
    accesses: data.accesses,
    placement: data.placement,
    action: data.action,
    actionValue: data.actionValue,
    diameter: data.diameter,
    menu: data.menu,
    isActive: data.isActive,
  };
};
