import { MultibuttonService } from '@/services';
import { IMultibutton, IMultibuttonFormValues } from '@/types';
import { errorAdapter } from '@/utils';
import { ActionContext } from 'vuex';

const multibuttonService = new MultibuttonService();

export default {
  actions: {
    async saveMultibutton(
      { commit }: ActionContext<unknown, unknown>,
      params: IMultibuttonFormValues,
    ): Promise<string> {
      try {
        const data = await multibuttonService.save(params);

        return data;
      } catch (e) {
        commit('setError', errorAdapter(e, 'Ошибка при сохранении мультикнопки'));
        throw e;
      }
    },

    async deleteMultibutton(
      { commit }: ActionContext<unknown, unknown>,
      uuid: string,
    ): Promise<string> {
      try {
        const data = await multibuttonService.delete(uuid);

        return data;
      } catch (e) {
        commit('setError', errorAdapter(e, 'Ошибка при удалении мультикнопки'));
        throw e;
      }
    },

    async fetchMultibutton(
      { commit }: ActionContext<unknown, unknown>,
      uuid: string,
    ): Promise<IMultibutton> {
      try {
        const data = await multibuttonService.getByUuid(uuid);

        return data;
      } catch (e) {
        commit('setError', errorAdapter(e, 'Ошибка при получении данных о кнопке'));
        throw e;
      }
    },
  },
};
