
import { defineComponent, onMounted, PropType, reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import { UploadOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  EMultibuttonAction,
  IDropdownValue,
  IMultibuttonAccess,
  IMultibuttonFormValues,
  IMultibuttonMenuItem,
} from '@/types';
import { UploadChangeParam } from 'ant-design-vue';
import {
  fontawesomeDropdown,
  getAccessMatchMap,
  getAccessTypeMap,
  getActionMap,
  getMenuActionMap,
  getPlacementMap,
} from '@/utils';

export default defineComponent({
  name: 'MultibuttonForm',

  components: {
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
  },

  props: {
    initValues: {
      type: Object as PropType<IMultibuttonFormValues>,
      required: true,
    },

    isAdmin: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();
    const store = useStore();
    const loading = ref<boolean>(false);
    const dropdownDomains = ref<IDropdownValue[]>([]);
    const uploadAPI = process.env.VUE_APP_API_URL + '/api/multibutton/upload';

    const dropdownAccessType: IDropdownValue[] = [...getAccessTypeMap().entries()].map(
      ([value, label]) => ({
        value,
        label,
      }),
    );

    const dropdownAccessMatch: IDropdownValue[] = [...getAccessMatchMap().entries()].map(
      ([value, label]) => ({
        value,
        label,
      }),
    );

    const dropdownPlacement: IDropdownValue[] = [...getPlacementMap().entries()].map(
      ([value, label]) => ({
        value,
        label,
      }),
    );

    const dropdownAction: IDropdownValue[] = [...getActionMap().entries()].map(
      ([value, label]) => ({
        value,
        label,
      }),
    );

    const dropdownMenuAction: IDropdownValue[] = [...getMenuActionMap().entries()].map(
      ([value, label]) => ({
        value,
        label,
      }),
    );

    const dropdownIcon = fontawesomeDropdown();

    const formValues = reactive<IMultibuttonFormValues>({
      ...props.initValues,
    });

    onMounted(async () => {
      dropdownDomains.value = await store.dispatch('fetchDropdownDomains');
    });

    const onFinish = async (values: IMultibuttonFormValues) => {
      try {
        loading.value = true;

        const uuid = await store.dispatch('saveMultibutton', {
          ...values,
          uuid: formValues.uuid,
        });

        if (formValues.uuid) {
          message.success('Данные успешно сохранены');
        } else {
          message.success('Кнопка успешно создана');

          const nextUrl = props.isAdmin
            ? `/admin/multibutton/edit/${uuid}`
            : `/client/multibutton/edit/${uuid}`;

          router.push(nextUrl);
        }
      } finally {
        loading.value = false;
      }
    };

    const filterOption = (input: string, option: any) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const handleImageChange = (info: UploadChangeParam) => {
      if (info.file.status === 'error') {
        message.error('Ошибка при загрузке файла');
        return;
      }
    };

    /** добавление/удаление правил доступа */

    const removeAccess = (item: IMultibuttonAccess) => {
      const index = formValues.accesses.indexOf(item);

      if (index !== -1) {
        formValues.accesses.splice(index, 1);
      }
    };

    const addAccess = () => {
      formValues.accesses.push({
        type: null,
        match: null,
        value: '',
      });
    };

    /** добавление/удаление пунктов меню */

    const removeMenuItem = (item: IMultibuttonMenuItem) => {
      const index = formValues.menu.indexOf(item);

      if (index !== -1) {
        formValues.menu.splice(index, 1);
      }
    };

    const addMenuItem = () => {
      const defaultColor = formValues.menu?.[0]?.color || '#ffffff';
      const defaultBackground = formValues.menu?.[0]?.background || '#002c5f';
      const defaultBackgroundHover = formValues.menu?.[0]?.backgroundHover || '#103771';

      formValues.menu.push({
        name: null,
        icon: null,
        color: defaultColor,
        background: defaultBackground,
        backgroundHover: defaultBackgroundHover,
        action: EMultibuttonAction.link,
        actionValue: null,
      });
    };

    return {
      EMultibuttonAction,
      dropdownDomains,
      dropdownAccessType,
      dropdownAccessMatch,
      dropdownPlacement,
      dropdownAction,
      dropdownMenuAction,
      dropdownIcon,
      filterOption,
      onFinish,
      formValues,
      handleImageChange,
      removeAccess,
      addAccess,
      removeMenuItem,
      addMenuItem,
      loading,
      uploadAPI,
    };
  },
});
