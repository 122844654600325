import { IDomain, IDomainFormValues } from '@/types';
import { BaseService } from './BaseService';

export class DomainService extends BaseService {
  readonly apiURL = '/api/domain';

  getList = async () => {
    const data = await this.get<IDomain[]>(`${this.apiURL}/get-list`);

    return data;
  };

  save = async (params: IDomainFormValues) => {
    const data = await this.post<string>(`${this.apiURL}/save`, params);

    return data;
  };

  delete = async (id: number) => {
    const data = await this.post<string>(`${this.apiURL}/delete`, {
      id,
    });

    return data;
  };

  getById = async (id: number) => {
    const data = await this.get<IDomain>(`${this.apiURL}/get-by-id/${id}`);

    return data;
  };
}
