
import { defineComponent, reactive } from 'vue';
import AccountLayout from '@/layouts/AccountLayout.vue';
import UserForm from '@/components/user/UserForm.vue';
import { ArrowLeftOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { EUserRole, IUser } from '@/types';

export default defineComponent({
  name: 'AdminUserCreate',

  components: {
    AccountLayout,
    ArrowLeftOutlined,
    UserForm,
  },

  setup() {
    const router = useRouter();

    const initValues = reactive<IUser>({
      id: 0,
      name: '',
      company: '',
      email: '',
      password: '',
      role: EUserRole.client,
    });

    const moveBack = () => {
      router.push('/admin/users');
    };

    return {
      moveBack,
      initValues,
    };
  },
});
